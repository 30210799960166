import React, { useState, useEffect, useRef } from 'react';
import { RealtimeClient } from '@openai/realtime-api-beta';
import { ItemType } from '@openai/realtime-api-beta/dist/lib/client.js';
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index.js';
import { instructions as defaultInstructions } from '../utils/conversation_config.js';
import { Button } from '../components/button/Button';
import { WavRenderer } from '../utils/wav_renderer';
import { useInterval } from 'react-use';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import './ConsolePage.scss';

const LOCAL_RELAY_SERVER_URL: string = process.env.REACT_APP_LOCAL_RELAY_SERVER_URL || '';

// Updated fetchFromGoogleSheets function
const fetchFromGoogleSheets = async (id: string): Promise<{
  instructions: string;
  logoUrl: string | null;
  sellerEmail: string | null;
  orgId: string | null;
  questions: string[];
  photos: { url: string; description: string }[];
  resultsPrompt: string | null; // New: Results prompt
  productFeedUrl: string | null; // Optional Product Feed URL
  productFeedData: string | null; // Optional Product Feed Data
}> => {
  const url = `https://script.google.com/macros/s/AKfycbz54rQjMTP-g-vFXVHlIXz_Ysz8mcJkYDtVNzRq1Pgd0svk22UCrRuPCKE31EUJwccF/exec?ID=${encodeURIComponent(id)}`;
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch: ${response.status}`);
    }
    const data = await response.json();
    if (data.error) {
      throw new Error(data.error);
    }

    const photos = Array.from({ length: 20 }).map((_, index) => ({
      url: data[`Photo_Link_${index + 1}`] || null,
      description: data[`Photo_Description_${index + 1}`] || null,
    })).filter(photo => photo.url);

    return {
      instructions: data.Instructions || '',
      logoUrl: data.SellerLogo || null,
      sellerEmail: data.SellerEmail || null,
      orgId: data.Org_ID || null,
      questions: data.Questions ? data.Questions.split(',').map((q: string) => q.trim()) : [],
      photos,
      resultsPrompt: data.Results_Prompt || null, // Retrieve Results Prompt
      productFeedUrl: data.Product_Feed || null, // Optional Product Feed URL
      productFeedData: data.Product_Feed_Data || null, // Retrieve Product Feed Data
    };
  } catch (error) {
    console.error('Error fetching from Google Sheets:', error);
    throw new Error('Failed to fetch instructions');
  }
};

const Logo: React.FC<{ logoUrl: string | null }> = ({ logoUrl }) => {
  if (logoUrl) {
    return (
      <img
        src={logoUrl}
        alt="Seller Logo"
        style={{
          maxWidth: '150px',
          maxHeight: '150px',
          width: 'auto',
          height: 'auto',
        }}
      />
    );
  }
  return (
    <div
      style={{
        fontSize: '150px', // Adjust size to fit the logo dimensions
        lineHeight: '150px',
        maxWidth: '150px',
        maxHeight: '150px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
      aria-label="Default Logo"
    >
      ☀️
    </div>
  );
};

const ConversationAnimation: React.FC<{
  clientCanvasRef: React.RefObject<HTMLCanvasElement>;
  serverCanvasRef: React.RefObject<HTMLCanvasElement>;
}> = ({ clientCanvasRef, serverCanvasRef }) => (
  <div style={{
    width: '150px',
    height: '64px',
    display: 'flex',
    margin: '16px 0',
  }}>
    <canvas ref={clientCanvasRef} style={{ width: '50%', height: '100%' }} />
    <canvas ref={serverCanvasRef} style={{ width: '50%', height: '100%' }} />
  </div>
);

interface RecommendProductsResponse {
  matches: Product[];
}

interface Product {
  product_id: string;
  product_name: string;
  description: string;
  price: string;
  image: string;
  link: string;
}

const Overlay: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  agreements: string[];
  onSubmit: (email: string) => Promise<void>; // Accept email as a parameter
}> = ({ isOpen, onClose, agreements, onSubmit }) => {
  const [email, setEmail] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const handleEmailSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (email) {
      setIsSubmitted(true);
      await onSubmit(email); // Pass the email to onSubmit
    }
  };

  const previewLength = 100;

  if (!isOpen || agreements.length === 0) {
    return null; // Do not render overlay until agreements are ready
  }

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
        overflowY: 'auto', // Make overlay scrollable
      }}
    >
      <div
        style={{
          backgroundColor: '#FFF',
          borderRadius: '8px',
          padding: '20px',
          maxWidth: '500px',
          width: '90%',
          textAlign: 'left', // Align content to the left
        }}
      >
        {!isSubmitted ? (
          <>
            <h2 style={{ fontSize: '1.5rem', marginBottom: '16px' }}>Summary Preview</h2>
            <p
              style={{
                backgroundColor: '#F9FAFB',
                padding: '12px',
                borderRadius: '4px',
                fontSize: '1rem',
                lineHeight: '1.5',
                color: '#374151',
                marginBottom: '16px',
                textAlign: 'left', // Align text to the left
              }}
            >
              {agreements
                .map((agreement) => agreement.slice(0, previewLength)) // Show preview for each agreement
                .join(' ')}
              ...
            </p>
            <p style={{ fontSize: '0.875rem', color: '#6B7280', marginBottom: '16px', textAlign: 'left' }}>
              Enter your email to view the full summary and save it.
            </p>
            <form onSubmit={handleEmailSubmit}>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
                style={{
                  width: '90%',
                  padding: '10px',
                  marginBottom: '16px',
                  border: '1px solid #CCC',
                  borderRadius: '4px',
                  fontSize: '1rem',
                }}
              />
              <Button type="submit" label="Submit" buttonStyle="action" />
            </form>
          </>
        ) : (
          <>
            <h2 style={{ fontSize: '1.5rem', marginBottom: '16px' }}>Full Summary</h2>
            <div
              style={{
                backgroundColor: '#F9FAFB',
                padding: '12px',
                borderRadius: '4px',
                fontSize: '1rem',
                lineHeight: '1.5',
                color: '#374151',
                textAlign: 'left', // Align text to the left
              }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  agreements.map((agreement) => marked.parse(agreement)).join('\n\n')
                ),
              }}
            />
            <p style={{ color: '#10B981', marginTop: '16px', textAlign: 'left' }}>
              The summary has been sent to your email!
            </p>
          </>
        )}
        <Button
          onClick={onClose}
          label="Close"
          buttonStyle="regular"
          style={{ marginTop: '16px' }}
        />
      </div>
    </div>
  );
};

export const ConsolePage: React.FC = () => {
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isConsentGiven, setIsConsentGiven] = useState<boolean>(false); // New state for consent checkbox
  const [currentInstructions, setCurrentInstructions] = useState<string>(defaultInstructions);
  const [conversationItems, setConversationItems] = useState<ItemType[]>([]);
  const [summary, setSummary] = useState<string>('');
  const [recommendedProducts, setRecommendedProducts] = useState<Product[]>([]);
  const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false);

  const wavRecorderRef = useRef<WavRecorder>(new WavRecorder({ sampleRate: 24000 }));
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(new WavStreamPlayer({ sampleRate: 24000 }));
  const clientRef = useRef<RealtimeClient | null>(null);

  const clientCanvasRef = useRef<HTMLCanvasElement>(null);
  const serverCanvasRef = useRef<HTMLCanvasElement>(null);

  const startTimeRef = useRef<string>(new Date().toISOString());
  const timeoutRef = useRef<NodeJS.Timeout | null>(null); // Ref to hold the timeout ID

  const [email, setEmail] = useState<string>(''); // Stores email input
  const [isEmailSending, setIsEmailSending] = useState(false); // Indicates if email submission is in progress
  const [isTranscriptSaving, setIsTranscriptSaving] = useState<boolean>(false); // Indicates if transcript is being saved

  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const [agreements, setAgreements] = useState<string[]>([]);

  const [sellerEmail, setSellerEmail] = useState<string | null>(null);

  const [orgId, setOrgId] = useState<string>('default-org-id');
  const [welcomeMessage, setWelcomeMessage] = useState<string>("Hello!"); // Default to "Hello!"
  const [dynamicQuestions, setDynamicQuestions] = useState<string[]>([]); // Empty initially

  const [photoMetadata, setPhotoMetadata] = useState<{ url: string; description: string }[]>([]);
  const [bestMatchingPhoto, setBestMatchingPhoto] = useState<{ url: string; description: string } | null>(null);

  const [resultsPrompt, setResultsPrompt] = useState<string | null>(null);
  const [productFeedUrl, setProductFeedUrl] = useState<string | null>(null); // Holds the product feed URL
  const [productFeedData, setProductFeedData] = useState<string | null>(null);

useEffect(() => {
  console.log('recommendedProducts state updated:', recommendedProducts);
}, [recommendedProducts]);

useInterval(
  async () => {
    if (isConnected) {
      const tasks: Promise<void>[] = [];

      // Add photo match task if more than one photo is available
      if (photoMetadata && photoMetadata.length > 1) {
        tasks.push(analyzePhotoMatch());
      } else {
        console.log('Skipping photo match: Not enough photos available.');
      }

      // Run all tasks in parallel
      if (tasks.length > 0) {
        await Promise.all(tasks);
      } else {
        console.log('No tasks to run in this interval.');
      }
    }
  },
  isConnected ? 30000 : null // Runs every 5 seconds during a conversation
);


useEffect(() => {
    if (error) {
        console.log('Error state updated:', error); // Debugging
    }
}, [error]);

useEffect(() => {
  const params = new URLSearchParams(window.location.search);
  const instructionsId = params.get('instructions') || 'default-id'; // Default to 'default-id'

  fetchInstructions(instructionsId); // Always fetch from Google Sheets
}, []);

const fetchInstructions = async (id: string) => {
  try {
    setIsLoading(true);

    if (id === 'default-id') {
      setCurrentInstructions(defaultInstructions);
      setLogoUrl(null);
      setSellerEmail(null);
      setDynamicQuestions(["Hello!"]);
      setWelcomeMessage("Hello!");
      setPhotoMetadata([]);
      setBestMatchingPhoto(null); // Clear initial photo for default case
      setResultsPrompt(null);
      setProductFeedUrl(null); // Reset product feed URL for default case
      setProductFeedData(null); // Reset Product Feed Data for default case
      initializeClient(defaultInstructions);
      return;
    }

    const { instructions, logoUrl, sellerEmail: fetchedEmail, orgId, questions, photos, resultsPrompt, productFeedUrl: fetchedProductFeedUrl, productFeedData: fetchedProductFeedData } =
      await fetchFromGoogleSheets(id);

    setCurrentInstructions(instructions);
    setLogoUrl(logoUrl);
    setSellerEmail(fetchedEmail);
    setOrgId(orgId || 'default-org-id');
    setPhotoMetadata(photos);
    setResultsPrompt(resultsPrompt);
    setProductFeedUrl(fetchedProductFeedUrl || null); // Update product feed URL
    setProductFeedData(fetchedProductFeedData || null); // Set Product Feed Data

    // Set the initial photo to the first one, if available
    if (photos.length > 0) {
      setBestMatchingPhoto(photos[0]);
    } else {
      setBestMatchingPhoto(null); // No photos available
    }

    if (questions.length > 0) {
      setWelcomeMessage(questions[0]);
      setDynamicQuestions(questions);
    } else {
      setDynamicQuestions(["Hello!"]);
      setWelcomeMessage("Hello!");
    }

    initializeClient(instructions);
  } catch (error) {
    console.error('Failed to fetch instructions:', error);
    setError('Failed to load instructions. Using default configuration.');
    setCurrentInstructions(defaultInstructions);
    setLogoUrl(null);
    setSellerEmail(null);
    setDynamicQuestions(["Hello!"]);
    setWelcomeMessage("Hello!");
    setPhotoMetadata([]);
    setBestMatchingPhoto(null); // Clear initial photo on error
    setResultsPrompt(null);
    setProductFeedData(null); // Reset Product Feed Data on error
    initializeClient(defaultInstructions);
  } finally {
    setIsLoading(false);
  }
};


const initializeClient = (instructions: string) => {
  console.log('Initializing client with instructions:', instructions);

  clientRef.current = new RealtimeClient(
    LOCAL_RELAY_SERVER_URL
      ? { url: LOCAL_RELAY_SERVER_URL }
      : {}
  );

  if (clientRef.current) {
    console.log('RealtimeClient initialized successfully');
    const client = clientRef.current;

    // Handle conversation interruption
    client.on('conversation.interrupted', async () => {
      console.log('Conversation interrupted');
      const trackSampleOffset = await wavStreamPlayerRef.current.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
        console.log('Interrupted response canceled:', { trackId, offset });
      }
    });

    // Handle conversation updates
    client.on('conversation.updated', async ({ item, delta }: any) => {
      console.log('Conversation update received:', item);

      const items = client.conversation.getItems();

      if (delta?.audio) {
        console.log('Audio delta received:', delta.audio);
        wavStreamPlayerRef.current.add16BitPCM(delta.audio, item.id);
      }

      if (item.status === 'completed' && item.formatted.audio?.length) {
        const wavFile = await WavRecorder.decode(
          item.formatted.audio,
          24000,
          24000
        );
        item.formatted.file = wavFile;
      }

      setConversationItems(items);
      console.log('Updated conversation items:', items);
    });

    setError(null);
  } else {
    console.error('RealtimeClient initialization failed');
  }
};

  useEffect(() => {
    let isLoaded = true;

    const wavRecorder = wavRecorderRef.current;
    const clientCanvas = clientCanvasRef.current;
    let clientCtx: CanvasRenderingContext2D | null = null;

    const wavStreamPlayer = wavStreamPlayerRef.current;
    const serverCanvas = serverCanvasRef.current;
    let serverCtx: CanvasRenderingContext2D | null = null;

    const render = () => {
      if (isLoaded) {
        if (clientCanvas) {
          if (!clientCanvas.width || !clientCanvas.height) {
            clientCanvas.width = clientCanvas.offsetWidth;
            clientCanvas.height = clientCanvas.offsetHeight;
          }
          clientCtx = clientCtx || clientCanvas.getContext('2d');
          if (clientCtx) {
            clientCtx.clearRect(0, 0, clientCanvas.width, clientCanvas.height);
            const result = wavRecorder.recording
              ? wavRecorder.getFrequencies('voice')
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(
              clientCanvas,
              clientCtx,
              result.values,
              '#0099ff',
              10,
              0,
              8
            );
          }
        }
        if (serverCanvas) {
          if (!serverCanvas.width || !serverCanvas.height) {
            serverCanvas.width = serverCanvas.offsetWidth;
            serverCanvas.height = serverCanvas.offsetHeight;
          }
          serverCtx = serverCtx || serverCanvas.getContext('2d');
          if (serverCtx) {
            serverCtx.clearRect(0, 0, serverCanvas.width, serverCanvas.height);
            const result = wavStreamPlayer.analyser
              ? wavStreamPlayer.getFrequencies('voice')
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(
              serverCanvas,
              serverCtx,
              result.values,
              '#009900',
              10,
              0,
              8
            );
          }
        }
        window.requestAnimationFrame(render);
      }
    };
    render();

    return () => {
      isLoaded = false;
    };
  }, []);

const handleConsentChange = () => {
    setIsConsentGiven((prev) => !prev);
    if (error) {
        setError(null); // Clear error when consent is given
    }
};
  
  const toggleConnection = async () => {
    if (!isConsentGiven) {
      setError('Please accept the terms to proceed.');
      return;
    }

    setIsLoading(true);
    if (!clientRef.current) {
      setError('Client not initialized. Please try again later.');
      setIsLoading(false);
      return;
    }

    if (isConnected) {
      await disconnectConversation();
    } else {
      await connectConversation();
    }
    setIsLoading(false);
  };

let toolsAdded = false; // Flag to ensure tools are added only once
  
const connectConversation = async () => {
  const client = clientRef.current;
  const wavRecorder = wavRecorderRef.current;
  const wavStreamPlayer = wavStreamPlayerRef.current;

  if (!client) {
    setError('Client not initialized. Please try again later.');
    console.error('Client is null. Initialization failed.');
    return;
  }

  try {
    if (!toolsAdded) {
      console.log('Adding tools dynamically...');

      // Add recommend_products tool
      if (productFeedUrl || productFeedData) {
        console.log('Adding recommend_products tool dynamically...');

        client.addTool(
          {
            name: 'recommend_products',
            description: 'Recommend and show 1 product based on the conversation and what the user is interested in',
            parameters: {
              type: 'object',
              properties: {
                productIds: {
                  type: 'array',
                  items: { type: 'string' },
                  description: 'The ID of the products to recommend.',
                },
              },
              required: ['productIds'],
            },
          },
          async ({ productIds }: { productIds: string[] }) => {
            try {
              if (!productFeedUrl) {
                throw new Error('Product feed URL is not available. Tool execution aborted.');
              }

              const payload = {
                csvUrl: productFeedUrl,
                action: 'recommend_products',
                productIds,
              };

              console.log('Sending request to Lambda for recommend_products:', payload);

              const response = await fetch('https://products-api.solaia.xyz/recommend_products', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
              });

              console.log('Received response for recommend_products:', {
                status: response.status,
                statusText: response.statusText,
                headers: [...response.headers.entries()],
              });

              if (!response.ok) {
                throw new Error(`Failed to fetch product recommendations: ${response.statusText}`);
              }

              const data = await response.json();
              console.log('Parsed response data for recommend_products:', data);

              setRecommendedProducts(data.matches); // Save recommendations to state
              return data.matches;
            } catch (error) {
              console.error('Error in recommend_products tool:', error);
              return { error: error instanceof Error ? error.message : 'Unknown error' };
            }
          }
        );

        toolsAdded = true; // Mark tools as added
      } else {
        console.log('Skipping tool addition.');
      }
    }

    // Inner try block for connection process
    try {
      console.log('Starting connection process...');

      // Interrupt playback and cancel ongoing AI response if applicable
      const trackSampleOffset = await wavStreamPlayer.interrupt();
      console.log('Playback interrupted:', trackSampleOffset);

      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        console.log('Canceling response with trackId:', trackId, 'and offset:', offset);
        await client.cancelResponse(trackId, offset);
      }

      // Set up initial state variables
      startTimeRef.current = new Date().toISOString();
      setIsConnected(true);

      // Connect to microphone
      console.log('Starting microphone recording...');
      await wavRecorder.begin();

      // Connect the audio output
      console.log('Connecting audio output...');
      await wavStreamPlayer.connect();

      // Connect to the AI client
      console.log('Connecting to the AI client...');
      await client.connect();

      // Send initial "Hello!" message to the AI
      console.log('Sending dynamic welcome message...');
      client.sendUserMessageContent([{ type: 'input_text', text: welcomeMessage }]);

      if (productFeedData) {
        console.log('Sending Product Feed Data from Google Sheets...');
        client.sendUserMessageContent([
          { type: 'input_text', text: 'Here is the product database in CSV format:' },
          { type: 'input_text', text: productFeedData },
        ]);
      } else {
        console.log('Product Feed Data is not available. Skipping.');
      }

      // Initialize session settings
      console.log('Updating session settings...');
      client.updateSession({
        instructions: currentInstructions, // Pass the instructions
        voice: 'alloy',
        input_audio_transcription: { model: 'whisper-1' },
        turn_detection: { type: 'server_vad' }, // Enable turn detection
      });

      // Start feeding audio data to the client
      console.log('Feeding audio data...');
      await wavRecorder.record((data) => {
        if (client.isConnected()) {
          client.appendInputAudio(data.mono);
        }
      });

      timeoutRef.current = setTimeout(async () => {
        console.log('Disconnecting due to timeout...');
        await disconnectConversation(); // Directly calls the disconnect logic
      }, 15 * 60 * 1000); // 15 minutes in milliseconds

      console.log('Connection successful.');
      setIsConnected(true);
      setError(null);
    } catch (error) {
      console.error('Error during connection:', error);
      setIsConnected(false);
      setError('Failed to connect to the AI. Please try again.');
    }
  } catch (error) {
    console.error('Error adding tools dynamically:', error);
  }
};
  
const saveTranscriptToGoogleSheet = async (sellerEmail: string | null = null) => {
  const url = 'https://script.google.com/macros/s/AKfycbweTjgDWzTRXs_eDjlJAuiZ60EOYBqSv3CNeBAejS36TmBp-kWJdWSbOE3nFYU4D9dP/exec';
  const transcript = conversationItems
    .map((item) => `${item.role}: ${item.formatted.transcript || '(No transcription available)'}`)
    .join('\n');

  const params = new URLSearchParams(window.location.search);
  const instructionsId = params.get('instructions') || 'default-id';

  const payload = {
    transcription: transcript,
    instructionsId: instructionsId,
    date: new Date().toISOString(),
    sellerEmail: sellerEmail,
    orgId: orgId, // Include Org_ID
    startQuestion: welcomeMessage, // Include the StartQuestion
  };

  try {
    const formData = new FormData();
    formData.append('data', JSON.stringify(payload));

    const response = await fetch(url, {
      method: 'POST',
      body: formData,
    });

    const result = await response.text(); // Get response as text first
    const data = JSON.parse(result);

    if (data.status !== 'Success') {
      throw new Error(`Server Error: ${data.message || 'Unknown error'}`);
    }

    console.log('Transcript saved:', data);
  } catch (error) {
    console.error('Error saving transcript:', error);
  }
};

const disconnectConversation = async () => {
  const client = clientRef.current;
  if (!client) return;

  const wavRecorder = wavRecorderRef.current;
  const wavStreamPlayer = wavStreamPlayerRef.current;

  client.disconnect();
  await wavRecorder.end();
  await wavStreamPlayer.interrupt();

  // Clear timeout when disconnected manually
  if (timeoutRef.current) {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = null;
  }

  // Ensure transcript is saved before proceeding to analysis
  await saveTranscriptToGoogleSheet(sellerEmail);

  // Run transcript analysis after disconnecting
  await analyzeTranscriptForMeeting();

  setIsConnected(false);
};

const analyzeTranscriptForMeeting = async () => {
  if (!resultsPrompt) {
    console.log('Skipping analysis: No results prompt available.');
    return;
  }

  // Prepare the transcript for the API call
  const transcript = conversationItems
    .map((item) => `${item.role}: ${item.formatted.transcript || ''}`)
    .join('\n');

  try {
    // Call the API to analyze the transcript
    const response = await fetch('https://api.solaia.xyz/analyze-transcript', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        transcript: transcript,
        systemPrompt: resultsPrompt, // Use dynamically fetched prompt
      }),
    });

    // Check for API errors
    if (!response.ok) {
      throw new Error(`API Error: ${response.statusText}`);
    }

    const data = await response.json();
    const rawContent = data.choices[0]?.message?.content || 'No actions identified.';

    // Save the full Markdown response directly
    setAgreements([rawContent]);

    console.log('Transcript analysis complete. Content:', rawContent);
  } catch (error) {
    console.error('Error analyzing transcript:', error);
    setAgreements(['Failed to analyze the transcript.']);
  }
};

const analyzePhotoMatch = async () => {
  const transcript = conversationItems
    .map((item) => `${item.role}: ${item.formatted.transcript || ''}`)
    .join('\n');

  try {
    console.log('Sending photo match payload:', {
      transcript,
      photos: photoMetadata,
    });

    const response = await fetch('https://api.solaia.xyz/match-photo', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        transcript,
        photos: photoMetadata,
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Photo match API error:', errorText);
      throw new Error(`Photo match request failed with status ${response.status}`);
    }

    const data = await response.json();
    console.log('Photo match response:', data);

    const bestPhoto = data.bestPhoto || null;
    setBestMatchingPhoto(bestPhoto);
  } catch (error) {
    console.error('Error matching photo:', error);
    setBestMatchingPhoto(null);
  }
};
  
const handleDisconnect = async () => {
  await disconnectConversation();

  // Ensure agreements are available before opening the overlay
  if (agreements.length > 0) {
    setIsOverlayOpen(true);
  } else {
    console.error('Agreements are not ready. Overlay will not open.');
  }
};
  
const generateAndSendSummary = async (email: string) => {
  setIsEmailSending(true);

  try {
    if (agreements.length === 0) {
      throw new Error('Agreements are not available. Please ensure analysis has been completed.');
    }

    const payload = {
      summary: agreements.join('\n\n'), // Join agreements as a single string
      email, // Pass the email
      sellerEmail,
      date: new Date().toISOString(),
    };

    const formData = new FormData();
    formData.append('data', JSON.stringify(payload));

    const sheetResponse = await fetch(
      'https://script.google.com/macros/s/AKfycbweTjgDWzTRXs_eDjlJAuiZ60EOYBqSv3CNeBAejS36TmBp-kWJdWSbOE3nFYU4D9dP/exec',
      {
        method: 'POST',
        body: formData,
      }
    );

    const result = await sheetResponse.text();
    const parsedResult = JSON.parse(result);

    if (parsedResult.status !== 'Success') {
      throw new Error(`Failed to save summary: ${parsedResult.message}`);
    }

    console.log('Summary and email saved:', parsedResult);
    setError(null);
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error in generateAndSendSummary:', error.message);
      setError('Failed to send summary. Please try again.');
    } else {
      console.error('Unknown error in generateAndSendSummary:', error);
      setError('An unknown error occurred. Please try again.');
    }
  } finally {
    setIsEmailSending(false);
  }
};


const ProductBox: React.FC<{ products: Product[] }> = ({ products }) => {
  useEffect(() => {
    console.log('ProductBox received products:', products);
  }, [products]);

  return (
    <div
      style={{
        width: '90%',
        maxWidth: '500px',
        margin: '16px auto',
        backgroundColor: '#FFF',
        padding: '16px',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      }}
    >
      <h3 style={{ fontSize: '1.5rem', fontWeight: 'bold', textAlign: 'center' }}>
        Recommended Products
      </h3>
      {products.length === 0 ? (
        <p style={{ textAlign: 'center', color: '#999' }}>No recommendations available.</p>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          {products.slice(0, 1).map((product) => (
            <div
              key={product.product_id} // Use a unique key
              style={{
                border: '1px solid #E5E7EB',
                borderRadius: '8px',
                padding: '8px',
                textAlign: 'center',
              }}
            >
              <img
                src={product.image}
                alt={product.product_name}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  borderRadius: '4px',
                  marginBottom: '8px',
                }}
              />
              <p style={{ fontSize: '1rem', fontWeight: 'bold' }}>{product.product_name}</p>
              <p style={{ fontSize: '0.875rem', color: '#374151' }}>{product.description}</p>
              <p style={{ fontSize: '1rem', fontWeight: 'bold', color: '#10B981' }}>
                {product.price} {/* Removed the '$' prefix to display '€' correctly */}
              </p>
              <a
                href={product.link}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: 'inline-block',
                  marginTop: '8px',
                  padding: '8px 16px',
                  backgroundColor: '#2563EB',
                  color: '#FFF',
                  borderRadius: '4px',
                  textDecoration: 'none',
                }}
              >
                Buy Now
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
  
const DisclaimerBox: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      style={{
        width: '90%',
        maxWidth: '500px',
        margin: '16px auto',
        backgroundColor: '#FFF',
        padding: '16px',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      }}
    >
     <h3
  style={{
    fontSize: '1.5rem',
    fontWeight: 'bold',
    cursor: 'pointer',
    textAlign: 'center',
  }}
  onClick={() => setIsOpen(!isOpen)}
>
  Disclaimer
</h3>
      {isOpen && (
<div style={{ textAlign: 'center' }}>
  <p>
    This website is a prototype and is intended solely for demonstration and development purposes. It is not a public offering. Content and functionalities may be incomplete or simulated and do not represent a binding scope of services.
  </p>
  <p>
    Use is restricted exclusively to internal testing and the presentation of concepts. Permanent storage or processing of personal data does not occur unless it is technically necessary for the operation of this demonstration environment.
  </p>
  <p>
    For inquiries, please send an email to{' '}
    <a href="mailto:contact@solaia.xyz">contact@solaia.xyz</a>
  </p>
</div>
      )}
    </div>
  );
};

const keyframes = `
  @keyframes pulse-button {
    0% {
      box-shadow: 0 0 0 0 rgba(37, 99, 235, 0.4); /* Button color with opacity */
    }
    70% {
      box-shadow: 0 0 0 20px rgba(37, 99, 235, 0); /* Fade out */
    }
    100% {
      box-shadow: 0 0 0 0 rgba(37, 99, 235, 0);
    }
  }
`;

useEffect(() => {
    const styleSheet = document.createElement('style');
    styleSheet.type = 'text/css';
    styleSheet.innerHTML = keyframes;
    document.head.appendChild(styleSheet);

    return () => {
        document.head.removeChild(styleSheet);
    };
}, []);
  
return (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minHeight: '100vh',
      backgroundColor: '#F3F4F6',
      padding: '16px',
    }}
  >
    <Logo logoUrl={logoUrl} />

    <div
      style={{
        width: '90%',
        maxWidth: '500px',
        margin: '16px auto',
        backgroundColor: '#FFF',
        padding: '16px',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      }}
    >
      <h2 style={{ textAlign: 'center' }}>How can I help you?</h2>

      {/* Consent Checkbox */}
<div
    style={{
        marginBottom: '16px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: !isConsentGiven && error ? '2px solid #EF4444' : 'none', // Red frame for missing consent
        borderRadius: '4px',
        padding: '8px',
        transition: 'border-color 0.3s ease-in-out',
    }}
>
    <label
        style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            cursor: 'pointer',
        }}
    >
        <input
            type="checkbox"
            checked={isConsentGiven}
            onChange={() => {
                setIsConsentGiven((prev) => !prev);
                if (error) {
                    setError(null); // Clear error on consent
                }
            }}
            style={{
                width: '20px',
                height: '20px',
                accentColor: isConsentGiven ? '#10B981' : '#D1D5DB',
                cursor: 'pointer',
            }}
        />
        <span style={{ fontSize: '0.875rem', color: '#374151' }}>
            I consent to the processing of my data according to our{' '}
            <a
                href="https://docs.google.com/document/d/e/2PACX-1vRcQ1lfc3X-_3x6cj-G2F509Ub5munVkvLZDINfMAQt4jhYpgInSjn5gboFRZn7Cf0jiCgAYnDMoUWj/pub"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#2563EB', textDecoration: 'underline' }}
            >
                policy
            </a>
        </span>
    </label>
</div>

    <Button
        onClick={async () => {
          if (!isConsentGiven) {
            setError('Please accept the terms to proceed.');
            return;
          }

          setError(null);

          if (isConnected) {
            await disconnectConversation();
            setIsOverlayOpen(true); // Open overlay after disconnecting
          } else {
            await toggleConnection();
          }
        }}
        buttonStyle={isConnected ? 'regular' : 'action'}
        label={isLoading ? 'Loading...' : isConnected ? 'Disconnect from AI Expert' : 'Talk to the AI Expert'}
        style={{
          animation: !isConnected ? 'pulse-button 1.5s infinite' : 'none',
        }}
      />
<Overlay
  isOpen={isOverlayOpen}
  onClose={() => setIsOverlayOpen(false)}
  agreements={agreements} // Pass the agreements to display the summary
  onSubmit={(email: string) => generateAndSendSummary(email)} // Pass the function for email submission
/>
      <ConversationAnimation
        clientCanvasRef={clientCanvasRef}
        serverCanvasRef={serverCanvasRef}
      />
    </div>
 {/* Conditionally render ProductBox */}
    {(productFeedUrl || productFeedData) && (
      <ProductBox products={recommendedProducts} />
    )}

{bestMatchingPhoto && (
  <div
    style={{
      width: '90%',
      maxWidth: '500px',
      margin: '16px auto',
      backgroundColor: '#FFF',
      padding: '16px',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      textAlign: 'center',
    }}
  >
    <img
      src={bestMatchingPhoto.url}
      alt="Agreement Photo"
      style={{
        maxWidth: '100%',
        height: 'auto',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      }}
    />
  </div>
)}
    {/* Disclaimer Box */}
    <DisclaimerBox />

    {error && <p style={{ marginTop: '16px', color: '#EF4444' }}>{error}</p>}
  </div>
);
};
